import React from 'react';
import { Countries } from '../../enums';
import i18n from '../../localization/i18n';
import * as rk from '../../localization/resourceKeys';

const Footer: React.FC<{ country: Countries }> = (props) => {
  const { country } = props;
  const dateRange = country === Countries.Canada ? new Date().getFullYear() : `1997-${new Date().getFullYear()}`;
  return (
    <div>
      <hr className='footer-hr' />
      <p className='footer-content'>
        © {dateRange} {i18n.t(country === Countries.Canada ? rk.FOOTER_MESSAGE_CANADA : rk.FOOTER_MESSAGE)}
      </p>
    </div>
  );
};

export default Footer;
